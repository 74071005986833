// Theme Colors/Branding
$brand-primary: #588965;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary:#E9D5B3;
$body_bkg_color: #E9D5B3;
$dark: #153A2A;
$light: #E9D5B3;
$medium: #c6b18f;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #E9D5B3;
$accent-2: #1459BA;
$body-font: #221E20;
$error: #d81e00;

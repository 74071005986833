body {
  background: $body_bkg_color;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

/** custom classes */
.w-xxl-auto {
  width:100%;
  @include media-breakpoint-up(xxxl) {
    width:auto;
  }
}
.inset-border {
  position:relative;
  &:before {
    content: '';
    height: calc(100% - 2em);
    width: calc(100% - 1em);
    border: solid 1px #E9D5B3;
    position: absolute;
    margin: 1em 0.5em;
    border-radius: 0.25em;
    display: inline-block;

    @include media-breakpoint-up(lg){
      height: calc(100% - 2em);
      width: calc(100% - 2em);
      margin: 1em;
    }
  }
}
.long-box-shadow {
  box-shadow:0px 2em 2em -1em rgba(0,0,0,.25);
}
.product-drop-shadow {
  filter:drop-shadow(0 2em 2em rgba(0, 0, 0, 0.5));
}
.product-description {
  ul {
    padding-left:0px;
    li {
      list-style:none;
      margin-bottom:.5em;
      display:flex;
    }
  }
}
.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 66vw;

  @include media-breakpoint-up(xl) {
    min-height: 450px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

// Images

img {
  max-width: 100%;
}

// Util
.img-invert-white {
  filter: brightness(0) invert(1);
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}
.z-index-2 {
  z-index:2;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}


.site-footer img {
  max-width:60%;
  width:auto;
}

img {
  @extend .rounded;
  @extend .rounded-3;
}

.offcanvas {
  background-attachment:fixed!important;
  .navbar-nav {
    .nav-link {
      color:$light;
      font-weight:normal;
      padding-top:1rem;
      padding-bottom:1rem;
      font-size:1.5em;
      font-family:$menu-font;
      text-transform:uppercase;
      text-align:center;
    }
    .dropdown-menu {
      position:relative!important;
      transform:none!important;
      background:lighten($dark,18%);
      border:0;
      .nav-item {
        .dropdown-item {
          color:white;
          &:hover {
            background:$dark;
            color:white;
          }
        }
      }
    }
  }
}

/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/
// set type

@font-face {
  font-family: 'Gnomon-Simple';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/Gnomon-Simple.woff2') format('woff2'),
  url('../../fonts/Gnomon-Simple.woff') format('woff');
}

@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/Gnomon-Simple.woff2') format('woff2'),
  url('../../fonts/Gnomon-Simple.woff') format('woff');
}




$menu-font: 'Bebas Neue', Helvetica, Arial, sans-serif;
$heading-font: 'Gnomon-Simple', Helvetica, Arial, sans-serif;
$subheading-font: 'Bebas Neue', Helvetica, Arial, sans-serif;
$paragraph-font: 'Bebas Neue', Helvetica, Arial, sans-serif; // set weights
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 600;
$heavy-weight: 700;
$ultra-weight: 900;

body {
  font-size: 24px;
  line-height: 1.3em;
  color: $body-font;
  font-family: $paragraph-font;
}
[class*="fs-"]{
  line-height: 1.3em;
}
a {
  color: $brand-primary;
  font-weight: $heavy-weight;
  text-decoration: none;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;

  &:hover {
    text-decoration: none;
    color: $brand-secondary;
  }
}

.bold,
strong {
  font-weight: 700;
}

.sub-heading {
  font-size: 1.25rem;
  font-family: $subheading-font;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
}

.h1,
.h2,
.h3,
h1,
h2,
h3
{
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight:bold;
  line-height: 1em;
  letter-spacing:.025em;
}
.h4,
.h5,
.h6, h4,
h5,
h6 {
  font-family: $heading-font;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight:bold;
  line-height: 1em;
  letter-spacing:.025em;
}
.h1,
h1 {
  font-weight: $heavy-weight;
  font-size: 3rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.5rem;
  }
}

.h2,
h2 {
  font-weight: $heavy-weight;
  font-size: 2.369rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.157rem;
  }
}

.h3,
h3 {
  font-weight: $heavy-weight;
  font-size: 1.777rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.369rem;
  }
}

.h4,
h4 {
  font-weight: $heavy-weight;
  font-size: 1.333rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.777rem;
  }
}

.h5,
h5 {
  font-size: 1rem;
  font-weight: $heavy-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.333rem;
  }
}
// Line height

.reduce-line-height {
  line-height: 1 !important;
}
.font-xl {
  font-size:2.5em;
  @include media-breakpoint-up(md) {
    font-size:3em;
  }
  @include media-breakpoint-up(xxl) {
    font-size:4em;
  }
}
